import type { HTMLProps } from "react";
import { useEffect, useRef, useCallback } from "react";
import type { WebGLRenderer, Scene, ShaderMaterial } from "three";
import { cn } from "~/utils/misc";

const CONFIG = {
  targetFPS: 30,
  frameInterval: 1000 / 30,
  pixelRatio: 1 / 32,
  blobScale: 0.8,
};

export default function MagicBlob({
  className,
}: {
  className?: HTMLProps<HTMLDivElement>["className"];
}) {
  const containerRef = useRef<HTMLDivElement>(null);
  const rendererRef = useRef<WebGLRenderer | null>(null);
  const sceneRef = useRef<Scene | null>(null);
  const materialRef = useRef<ShaderMaterial | null>(null);
  const frameRef = useRef<number>(0);

  const cleanup = useCallback(() => {
    if (frameRef.current) {
      cancelAnimationFrame(frameRef.current);
      frameRef.current = 0;
    }

    if (rendererRef.current) {
      const renderer = rendererRef.current;
      if (renderer.domElement.parentElement) {
        try {
          renderer.domElement.parentElement.removeChild(renderer.domElement);
        } catch (e) {
          // Suppress removal errors during hot reload
        }
      }
      renderer.dispose();
      rendererRef.current = null;
    }

    if (materialRef.current) {
      materialRef.current.dispose();
      materialRef.current = null;
    }

    if (sceneRef.current) {
      sceneRef.current = null;
    }
  }, []);

  useEffect(() => {
    let isActive = true;
    let resizeObserver: ResizeObserver | null = null;

    const initializeScene = async () => {
      const THREE = await import("three");
      if (!containerRef.current || !isActive) return;

      const scene = new THREE.Scene();
      sceneRef.current = scene;

      const renderer = new THREE.WebGLRenderer({
        antialias: false,
        alpha: true,
        powerPreference: "low-power",
        stencil: false,
        depth: false,
        precision: "lowp",
      });

      const containerBounds = containerRef.current.getBoundingClientRect();
      renderer.setPixelRatio(CONFIG.pixelRatio);
      renderer.setSize(containerBounds.width, containerBounds.height);
      containerRef.current.appendChild(renderer.domElement);
      rendererRef.current = renderer;

      const camera = new THREE.OrthographicCamera(-1, 1, 1, -1, 0, 1);

      const material = new THREE.ShaderMaterial({
        uniforms: {
          time: { value: 0 },
          resolution: {
            value: new THREE.Vector2(
              containerBounds.width * CONFIG.pixelRatio,
              containerBounds.height * CONFIG.pixelRatio,
            ),
          },
          colorA: { value: new THREE.Color(228 / 255, 132 / 255, 185 / 255) },
          colorB: { value: new THREE.Color(248 / 255, 199 / 255, 148 / 255) },
          blobScale: { value: CONFIG.blobScale },
        },
        vertexShader: `
          void main() {
            gl_Position = vec4(position, 1.0);
          }
        `,
        fragmentShader: `
          uniform float time;
          uniform vec2 resolution;
          uniform vec3 colorA;
          uniform vec3 colorB;
          uniform float blobScale;

          void main() {
              vec2 uv = gl_FragCoord.xy/resolution.xy;
              vec2 centered = uv * 2.0 - 1.0;
              centered.x *= resolution.x/resolution.y;
              
              float blobNoise = sin(centered.x * 3.0 + time * 1.0) * 
                              cos(centered.y * 4.0 + time * 0.6) * 0.5;
              
              float moveScale = 1.0 - blobScale;
              vec2 moved = vec2(
                  centered.x + cos(time * 0.3) * 0.2 * moveScale,
                  centered.y + sin(time * 0.4) * 0.2 * moveScale
              );
              
              float dist = length(moved);
              float alpha = smoothstep(blobScale, blobScale * .1, dist + blobNoise * 0.3);
              float mixFactor = clamp(blobNoise + sin(time * 0.2) * 0.5 + 0.5, 0.0, 1.0);
              vec3 color = mix(colorA, colorB, mixFactor);
              
              gl_FragColor = vec4(color, alpha);
          }
        `,
        transparent: true,
        blending: THREE.NormalBlending,
        precision: "lowp",
      });
      materialRef.current = material;

      const geometry = new THREE.PlaneGeometry(2, 2);
      const mesh = new THREE.Mesh(geometry, material);
      scene.add(mesh);

      resizeObserver = new ResizeObserver((entries) => {
        if (!isActive || !renderer) return;

        const entry = entries[0];
        if (!entry) return;

        const width = entry.contentRect.width;
        const height = entry.contentRect.height;

        renderer.setSize(width, height);
        material.uniforms.resolution.value.set(
          width * CONFIG.pixelRatio,
          height * CONFIG.pixelRatio,
        );
      });

      resizeObserver.observe(containerRef.current);

      const startTime = Date.now();
      let lastFrameTime = 0;

      function animate(currentTime: number) {
        if (!isActive || !rendererRef.current) return;

        frameRef.current = requestAnimationFrame(animate);

        const deltaTime = currentTime - lastFrameTime;
        if (deltaTime < CONFIG.frameInterval) return;

        const elapsedTime = (Date.now() - startTime) * 0.001;
        material.uniforms.time.value = elapsedTime;
        renderer.render(scene, camera);

        lastFrameTime = currentTime;
      }

      frameRef.current = requestAnimationFrame(animate);

      geometry.dispose();
    };

    cleanup();
    initializeScene();

    return () => {
      isActive = false;
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
      cleanup();
    };
  }, [cleanup]);

  return (
    <div
      ref={containerRef}
      className={cn(
        "pointer-events-none relative aspect-square min-w-0",
        className,
      )}
    />
  );
}
