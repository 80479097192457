import { useLocation, useSearchParams } from "@remix-run/react";

const steps = [
  { path: "/signup", label: "Enter Email" },
  { path: "/verify", label: "Verify Email" },
  { path: "/onboarding", label: "Setup Account" },
];

export function useSignupSteps() {
  const location = useLocation();
  const currentStepIndex = steps.findIndex((step) =>
    location.pathname.startsWith(step.path),
  );
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type") ?? null;

  return {
    steps,
    currentStepIndex,
    isSignupFlow: currentStepIndex !== -1 && type !== "2fa",
  };
}
